<template>
  <div>
    <div class="headD"></div>
    <div class="head">
      <a href="" class="logo"></a>
      <div class="login-btn">
        <a href="http://link.yzysaas.com/login">平台登录</a>
      </div>
      <a href="javascript:" class="navA"></a>
      <div class="nav">
        <ul>
          <li><a href="javascript:">关于我们</a></li>
          <li><a href="javascript:">业务范畴</a></li>
          <li><a href="javascript:">平台优势</a></li>
          <li><a href="javascript:">服务流程</a></li>
          <li><a href="javascript:">团队优势</a></li>
        </ul>
      </div>
    </div>

    <div class="indexFlash">
      <ul class="swiper-wrapper">
        <!--        <li style="background-image: url('static/image/banner1.jpg')" class="swiper-slide">
          <div class="layer">
            <h3>专注陆路集装箱物流</h3>
            <p>优可达-线上下单就可以了</p>
          </div>
        </li>-->
        <li
          style="background-image: url('static/image/banner2.jpg')"
          class="swiper-slide"
        >
          <div class="layer">
            <h3>专注陆路集装箱物流</h3>
            <p>优可达-线上下单就可以了</p>
          </div>
        </li>
      </ul>
      <div class="swiper-pagination"></div>
    </div>

    <div class="index-about page">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <div class="en">ABOUT US</div>
          <h3>关于我们</h3>
        </div>
        <div class="img wow animate__animated animate__slideInLeft">
          <img src="static/image/img930.png" alt="" />
        </div>
        <div class="container">
          <div class="content wow animate__animated animate__slideInUp">
            广东优可达物流服务有限公司是一家采用全新互联网协作方式提供进出口物流服务的企业，主营珠三角各码头拖车、进出口清关、仓储、单证等业务，在深圳、广州、佛山等广东境内各码头单日可调配车辆800+；报关经验丰富，应急能力强。拥有行业最先进的港口物流供应链云平台，采用新一代港口物流互联网协作方式，实现全面数字化运营，通过PC端、公众号、小程序多种互联网渠道，赋予物流协同可视化、数据传递实时化、作业成本最小化，提供一站式高品质、高效率的物流服务。
          </div>
          <div class="word wow animate__animated animate__slideInUp">
            <img src="static/image/img369.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="index-business page">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInLeft">
          <div class="en">SCOPE OF <br />BUSINESS</div>
          <h3>业务范围</h3>
        </div>
        <div class="list">
          <ul>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img181_1.png" alt="" />
              </div>
              <h3>运输服务</h3>
              <p>
                承接中国华南地区间陆路集装箱运输业务，<br />
                服务涵盖盐田、蛇口、南沙、黄埔、佛山<br />
                中山江门等各码头
              </p>
            </li>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img181_2.png" alt="" />
              </div>
              <h3>报关服务</h3>
              <p>
                提供华南地区通关口岸各类产品出口<br />
                报关报检、进口清关服务
              </p>
            </li>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img181_3.png" alt="" />
              </div>
              <h3>单证服务</h3>
              <p>
                代为办理各项国外目的港清关<br />
                单证：熏蒸、各类产地证书、<br />
                各项检测证书
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index-advantages page">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <div class="en">PLATFORM <br />ADVANTAGES</div>
          <h3>平台优势</h3>
        </div>
        <div class="list">
          <ul>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img50_1.png" alt="" />
              </div>
              <p>一体化移动互联网解决方案，全生命周期的物流可视化服务体验；</p>
            </li>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img50_2.png" alt="" />
              </div>
              <p>平台智能接单、派单、问题反馈，及时可靠；</p>
            </li>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img50_3.png" alt="" />
              </div>
              <p>打通一线司机与客户直接通话窗口，司机提柜还柜进展实时更新；</p>
            </li>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img50_4.png" alt="" />
              </div>
              <p>每单业务费用智能推送客户对账，明白放心；</p>
            </li>
          </ul>
        </div>
        <div class="content wow animate__animated animate__slideInUp">
          数字化赋能平台客服、司机，让工作高效、轻松、<br />
          开心，给客户更智能、更人性化的服务体验；
        </div>
        <div class="img"><img src="static/image/img1012.png" alt="" /></div>
      </div>
    </div>

    <div class="index-channel">
      <div class="wal">
        <div class="title wow animate__animated animate__slideInUp">
          <div class="en">OMNI CHANNEL COVERAGE</div>
          <h3>全渠道覆盖</h3>
          <p>
            平台融合了PC端、微信公众号、小程序为一体，增强用户粘性、提升服务品质，Get到用户需求。
          </p>
        </div>
        <div class="list">
          <ul>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img102_1.png" alt="" />
              </div>
              <div class="name">PC端</div>
            </li>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img102_2.png" alt="" />
              </div>
              <div class="name">微信小程序</div>
            </li>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img102_3.png" alt="" />
              </div>
              <div class="name">微信公众号</div>
            </li>
            <li class="wow animate__animated animate__slideInUp">
              <div class="ico">
                <img src="static/image/img102_4.png" alt="" />
              </div>
              <div class="name">后台管理系统</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index-service">
      <div class="wal">
        <div class="title wow animate__animated animate__slideInUp">
          <div class="en">ONE STOP LOGISTICS SERVICE</div>
          <h3>一站式物流服务</h3>
        </div>
        <div class="content wow animate__animated animate__slideInUp">
          平台整合了客户、平台客服、托运调度、报关员、单证员以及司机等多方角色，赋予物流协同可视化、数据传递实时化、管理成本最小化，提高物流服务效率与品质。
        </div>
        <div class="list wow animate__animated animate__zoomIn">
          <div class="title2">
            <h3>优可达</h3>
            <p>一站式物流服务</p>
          </div>
          <ul>
            <li class="li_01">
              <div class="ico">
                <img src="static/image/img64_3.png" alt="" /><img
                  src="static/image/img64_3on.png"
                  alt=""
                />
              </div>
              <div class="name">客户</div>
            </li>
            <li class="li_02">
              <div class="ico">
                <img src="static/image/img64_4.png" alt="" /><img
                  src="static/image/img64_4on.png"
                  alt=""
                />
              </div>
              <div class="name">平台客服</div>
            </li>
            <li class="li_03">
              <div class="ico">
                <img src="static/image/img64_2.png" alt="" /><img
                  src="static/image/img64_2on.png"
                  alt=""
                />
              </div>
              <div class="name">托运调度</div>
            </li>
            <li class="li_04">
              <div class="ico">
                <img src="static/image/img64_5.png" alt="" /><img
                  src="static/image/img64_5on.png"
                  alt=""
                />
              </div>
              <div class="name">司机</div>
            </li>
            <li class="li_05">
              <div class="ico">
                <img src="static/image/img64_6.png" alt="" /><img
                  src="static/image/img64_6on.png"
                  alt=""
                />
              </div>
              <div class="name">报关员</div>
            </li>
            <li class="li_06">
              <div class="ico">
                <img src="static/image/img64_1.png" alt="" /><img
                  src="static/image/img64_1on.png"
                  alt=""
                />
              </div>
              <div class="name">单证员</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index-process page">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <div class="en">SERVICE <br />PROCESS</div>
          <h3>服务流程</h3>
        </div>
        <div class="list wow animate__animated animate__slideInUp">
          <ul>
            <li>
              <div class="ico">
                <img src="static/image/img130_1.png" alt="" />
              </div>
              <div class="name">在线下单</div>
            </li>
            <li>
              <div class="ico">
                <img src="static/image/img130_2.png" alt="" />
              </div>
              <div class="name">平台接单</div>
            </li>
            <li>
              <div class="ico">
                <img src="static/image/img130_3.png" alt="" />
              </div>
              <div class="name">智能调度</div>
            </li>
            <li>
              <div class="ico">
                <img src="static/image/img130_4.png" alt="" />
              </div>
              <div class="name">进度可视化监控</div>
            </li>
            <li>
              <div class="ico">
                <img src="static/image/img130_5.png" alt="" />
              </div>
              <div class="name">在线信息反馈</div>
            </li>
            <li>
              <div class="ico">
                <img src="static/image/img130_6.png" alt="" />
              </div>
              <div class="name">状态更新提醒</div>
            </li>
            <li>
              <div class="ico">
                <img src="static/image/img130_7.png" alt="" />
              </div>
              <div class="name">自动生成对账单</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="index-team page">
      <div class="wal">
        <div class="index-title wow animate__animated animate__slideInUp">
          <div class="en">TEAM<br />ADVANTAGE</div>
          <h3>团队优势</h3>
        </div>
        <div class="title wow animate__animated animate__slideInUp">
          <img src="static/image/img303.png" alt="" />
        </div>
        <div class="content wow animate__animated animate__slideInUp">
          专业的本地化物流服务团队，快速的客户需求响应。<br />
          专注于服务“出海”中国企业长达15年。<br />
          丰富的团队运营经验以及车辆资源，快速调度、快速对应各类问题。<br />
          15年报关与单证服务经验沉淀，服务标准化、信誉好、效率高。
        </div>
      </div>
    </div>

    <div class="foot">
      <div class="wal">
        <!-- <div class="link">
          <ul>
            <li>友情链接：</li>
            <li><a href="">链接1</a></li>
            <li><a href="">链接2</a></li>
            <li><a href="">链接3</a></li>
            <li><a href="">链接4</a></li>
            <li><a href="">链接5</a></li>
          </ul>
        </div> -->
        <div class="content">
          <p>广东优可达物流服务有限公司</p>
          <p>Copyright ©2022-2025 Ukeda All Rights Reserved.</p>
          <p>
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >粤ICP备2022014207号-1</a
            >
          </p>
          <p>地址：广东省佛山市南海区桂城街道季华东路天安中心13座1205室</p>
          <p>联系电话：0757-86329890（办公室）</p>
        </div>
        <!--        <div class="ewm"><img src="static/image/ewm247.png" alt=""></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs';
import $ from 'jquery';
fontSize();
$(window).resize(function () {
  fontSize();
});

function fontSize() {
  var size;
  var winW = window.innerWidth;
  if (winW <= 1600 && winW > 800) {
    size = Math.round(winW / 16);
  } else if (winW <= 800) {
    size = Math.round(winW / 7.5);
    if (size > 65) {
      size = 65;
    }
  } else {
    size = 100;
  }
  $('html').css({
    'font-size': size + 'px'
  });
}

import Swiper from 'swiper';

export default {
  name: 'Home',
  components: {},
  mounted() {
    this.$nextTick(() => {
      //页面渲染完，在执行
      let wow = new WOW({
        live: false
      });
      wow.init();

      this.navTo();
      this.phoneNav();
      this.indexFlash();
    });
  },
  methods: {
    navTo: function () {
      let nav = $('.nav').find('li');
      nav.each(function (i) {
        $(this)
          .find('a')
          .click(function () {
            $('body,html')
              .stop(true, true)
              .animate(
                {
                  scrollTop: $('.page').eq(i).offset().top - $('.head').height()
                },
                300
              );
            $('body').removeClass('navShow');
          });
      });
      $(window).scroll(function () {
        $('.page').each(function (i) {
          if (
            $(window).scrollTop() >
            $(this).offset().top - $(window).height() / 2
          ) {
            nav.find('a').removeClass('on');
            nav.eq(i).find('a').addClass('on');
          }
        });
      });
    },
    phoneNav: function () {
      $('.navA').click(function () {
        if ($('body').hasClass('navShow')) {
          $('body').removeClass('navShow');
        } else {
          $('body').addClass('navShow');
        }
      });
    },
    indexFlash: function () {
      if ($('.indexFlash').find('li').length > 1) {
        new Swiper('.indexFlash', {
          loop: true,
          autoplay: 500,
          effect: 'fade',
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets'
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'public/static/css/index';
@import 'swiper/swiper.scss';
.login-btn {
  position: absolute;
  z-index: 1;
  left: auto;
  right: 0.5rem;
  top: 30px;
  /* bottom: 0; */
  line-height: 0.3rem;
  background-color: #ed7318;
  padding: 10px;
  border-radius: 5px;
}
</style>
