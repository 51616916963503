<template>
    <div>
        <page-head></page-head>
        <page-side></page-side>
        <div class="g-container">
            about
        </div>
    </div>
</template>

<script>
    import PageHead from "../components/PageHead";
    import PageSide from "../components/PageSide";
    export default {
        name: "About",
        components:{
            PageHead,
            PageSide
        }
    };
</script>

<style lang="scss" scoped>

</style>
