<template>
    <div class="login">
        <div class="wal">
            <div class="side">
                <div class="logo"><img src="static/image/logo.png" alt=""></div>
                <h2>优可达订单管理中心</h2>
            </div>
            <!--login-->
            <div class="container" v-show="page == 'login'">
                <div class="title">
                    <h3>登录</h3>
                    <div class="col">
                        <p>我没有账号，点击</p>
                        <a href="javascript:" @click="page = 'reg'">免费注册</a>
                    </div>
                </div>
                <el-tabs v-model="loginName" type="card">
                    <el-tab-pane label="微信登录" name="ewm">
                        <div class="ewm">
                            <div class="img"><img src="static/image/img223.png" alt=""></div>
                            <p>请使用微信扫描二维码登录</p>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="手机登录" name="phone">
                        <div class="form">
                            <ul>
                                <li>
                                    <div class="name">手机号 *</div>
                                    <el-input v-model="input" placeholder="请输入..."></el-input>
                                </li>
                                <li>
                                    <div class="name">验证码 *</div>
                                    <el-row :gutter="12">
                                        <el-col :span="16">
                                            <el-input v-model="input" placeholder="请输入..."></el-input>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-button style="width: 100%; padding: 12px 0">发送验证码</el-button>
                                        </el-col>
                                    </el-row>
                                </li>
                            </ul>
                            <div class="submit">
                                <el-button type="primary">登 录</el-button>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="账号登录" name="account">
                        <div class="form">
                            <ul>
                                <li>
                                    <div class="name">账号 *</div>
                                    <el-input placeholder="请输入..."></el-input>
                                </li>
                                <li>
                                    <div class="name">密码 *</div>
                                    <el-input v-model="input" show-password placeholder="请输入..."></el-input>
                                </li>
                            </ul>
                            <div class="submit">
                                <el-button type="primary">登 录</el-button>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <!--reg-->
            <div class="container" v-show="page == 'reg'">
                <div class="title">
                    <h3>注册</h3>
                    <div class="col">
                        <p>我有账号，现在去</p>
                        <a href="javascript:" @click="page = 'login'">登录</a>
                    </div>
                </div>
                <div class="form">
                    <ul>
                        <li>
                            <div class="name">账号 *</div>
                            <el-input placeholder="请输入..."></el-input>
                        </li>
                        <li>
                            <div class="name">手机 *</div>
                            <el-input placeholder="请输入..."></el-input>
                        </li>
                        <li>
                            <div class="name">公司名称 *</div>
                            <el-input placeholder="请输入..."></el-input>
                        </li>
                        <li>
                            <div class="name">公司类型 *</div>
                            <el-radio v-model="companyType" label="1">委托商</el-radio>
                            <el-radio v-model="companyType" label="2">供应商</el-radio>
                        </li>
                        <li v-if="companyType == '2'">
                            <div class="name">服务类型 *</div>
                            <el-checkbox-group v-model="serviceType">
                                <el-checkbox label="拖车"></el-checkbox>
                                <el-checkbox label="报关"></el-checkbox>
                                <el-checkbox label="单证"></el-checkbox>
                            </el-checkbox-group>
                        </li>
                    </ul>
                </div>
                <div class="submit">
                    <el-button type="primary">注 册</el-button>
                </div>
            </div>
            <!---->
        </div>
        <div class="msg">©版权所有 2021 优可达</div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data:function () {
            return{
                page: 'login',//登录还是注册login/reg
                loginName: 'ewm',//登录方式ewm/phone/account
                companyType: '1',//公司类型
                serviceType: ['拖车','报关'],//服务类型
                input:''
            }
        },
        mounted: function(){
            if(this.$route.query.page !== undefined){
                this.page = this.$route.query.page
            }
        },
        methods: {
            login:function () {
                this.loginName = this.loginName === 'form'?'ewm':'form'
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "public/static/css/public";

    .login{position: fixed; left: 0; top: 0; right: 0; bottom: 0; background: $color url("../assets/image/login.png") center no-repeat; background-size: cover; font-size: 14px;
        .wal{width: 1100px; height: 700px; background: #fff; @include radius(12px); @include absolute($l:50%, $t:50%); margin-left: -550px; margin-top: -350px; @include shadow(0 0 .2rem rgba(0, 0, 0, .1)); overflow: hidden}
        .side{@include absolute($l:0, $t:0, $b:0); background: url("../assets/image/logo-413-blue.png") center no-repeat; background-size: cover; width: 413px; @extend .flexColumn; @extend .justifyCenter; @extend .itemsCenter; text-align: center; color: #fff; padding-right: 20px;
            .logo{width: 340px}
            h2{font-size: 30px; padding-top: 25px; letter-spacing: 2px; text-shadow: 0 2px 2px rgba(0, 0, 0, .2)}
        }
        .container{width: 493px; padding-right: 173px; height: 700px; @extend .flexColumn; @extend .justifyCenter; margin-left: auto;
            .title{@extend .flex; @extend .itemsCenter; @extend .flexBetween; margin-bottom: 25px; border-bottom: #eee solid 1px; height: 70px;
                h3{font-size: 28px}
                .col{@extend .flex; @extend .itemsCenter; font-size: 13px; color: #666;
                    a{border: #ddd solid 1px; background: #f5f5f5; @include radius(3px); line-height: 30px; padding: 0 16px; margin-left: 9px; @extend .transition; color: $color;
                        &:hover{background: $color; border-color: $color; color: #fff}
                    }
                }
            }
            .form{min-height: 284px;
                li{padding-bottom: 25px; position: relative;
                    .name{line-height: 1em; padding-bottom: 8px}
                }
                li.password{
                    .input-text{@include absolute($l:0, $b:0, $r:0); opacity: 0; visibility: hidden}
                    a.btn{display: block; width: 26px; height: 40px; @include absolute($r:0, $b:0); background: url("../assets/image/img6.png") center no-repeat}
                    &.on{
                        .input-password{opacity: 0; visibility: hidden}
                        .input-text{opacity: 1; visibility: visible}
                    }
                }
                li.lzm{
                    .img{@include absolute($r:0, $b:10px)}
                    a.btn{display: block; @include absolute($r:0, $b:9px); @include radius(3px); width: 82px; height: 32px; line-height: 30px; text-align: center; border: #1890ff solid 1px; color: #1890ff; font-size: 18px}
                }
            }
            .submit{padding-top: 15px;
                button{width: 100%}
            }
            .ewm{min-height: 284px;
                p{width: 223px; text-align: center; padding-top: 15px; font-size: 14px; color: #666}
            }
        }
        .msg{@include absolute($l:0, $r:0, $b:20px); text-align: center; color: #fff}
        .btn2{@include absolute($r:10px, $b:10px); background: url("../assets/image/img58_1.png") center no-repeat; width: 58px; height: 58px; cursor: pointer;
            &.on{background-image: url("../assets/image/img58_2.png")}
        }
    }
</style>
